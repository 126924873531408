.truncated {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wrapped {
  white-space: normal;
  text-align: left;
}

.left-text {
  text-align: left;
}

.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.vfont-body {
  @include vfont-body;
}

.vfont-body-big {
  @include vfont-body-big;
}

.opacity-80 {
  opacity: 0.8;
}

.user-content-none {
  margin: 50px 0;
  font-size: 1rem;
  text-align: left;
  a {
    font-weight: 900;
  }
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}
.loading, .loading:after {
  border-radius: 50%;
  width: 16px;
  height: 16px;
}
.loading {
  transform: translate(-50%, -50%);
  text-indent: -9999em;
  border-top: 4px solid rgba(0,0,0,0);
  border-right: 4px solid var(--color-light-gray);
  border-bottom: 4px solid var(--color-light-gray);
  border-left: 4px solid var(--color-light-gray);
  animation: load8 1.1s infinite linear;
}
.centered-loading {
  width: 100%;
  display: flex;
  justify-content: center;
}
@keyframes load8 {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.float-left {
  float: left;
}
.float-right {
  float: right;
}
.voloco-logo {
  height: 30px;
  padding: 16px 16px 0 16px;

  &.no-padding {
    padding: unset;
  }
}

.flex-column-end {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 100%;
  height: max-content;
}

.pagination {
  display: flex;
  justify-content: center;
  > a {
    &[disabled] {
      opacity: 0.3;
    }
    color: white;
  }
  > * {
    margin: 0 24px;
  }
}

.video-embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  margin-bottom: 32px;

  iframe, object, embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 16px;

  &.space-around {
    justify-content: space-around;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.justify-end {
    justify-content: flex-end;
  }

  &.align-stretch {
    align-items: stretch;
  }

  &.align-center {
    align-items: center;
  }

  &.align-start {
    align-items: flex-start;
  }

  &.gap-72 {
    gap: 72px;
  }
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 16px;

  &.space-around {
    justify-content: space-around;
  }

  &.align-stretch {
    align-items: stretch;
  }

  &.align-center {
    align-items: center;
  }

  &.align-start {
    align-items: flex-start;
  }

  &.gap-8 {
    gap: 8px;
  }

  &.gap-4 {
    gap: 4px;
  }

  &.gap-0 {
    gap: 0px;
  }
}

.flex-basis-0 {
  flex-basis: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.margin-bottom-120 {
  margin-bottom: 120px;
}

.margin-vertical-small {
  margin: 16px 0;
}

.margin-16 {
  margin: 16px;
}

.margin-vertical-8 {
  margin: 8px 0;
}

.margin-right-16 {
  margin-right: 16px;
}

.padding-8 {
  padding: 8px;
}

.padding-8-0 {
  padding: 8px 0;
}

.link {
  color: var(--color-primary);
}

.recharts-legend-item-text {
  color: white !important;
}

.min-width-225 {
  min-width: 225px;
}

.max-width-200 {
  max-width: 200px;
}

.max-width-600 {
  max-width: 600px;
}

.width-100p {
  width: 100%;
}

.width-85vw {
  width: 85vw;
}

.width-200px {
  width: 200px;
}

.width-fit-content {
  width: fit-content;
}

.red-outline {
  background-color: unset;
  border: 2px solid var(--color-secondary);
  color: var(--color-secondary);
  border-radius: 8px;
  padding: 6px 12px;
}

.color-text {
  color: var(--text-color);
}

.color-text-light {
  color: var(--text-color-light);
}

.public-user-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
  color: var(--white-100);
  @include vfont-18;

  @include breakpoint($small) {
    @include vfont-26;
  }

  img {
    width: 32px;
    height: 32px;
    object-fit: contain;
    border-radius: 50%;
  }

  &-small {
    @include vfont-button;
    color: var(--white-70);
  }
}

.public-item-link {
  color: var(--white-100);
  font-size: 16px;
}

.position-relative {
  position: relative;
}

.play-pause-overlay {
  cursor: pointer;
  position: absolute;
  width: 3.5rem;
  height: 3.5rem;

  @include breakpoint($small) {
    width: 5rem;
    height: 5rem;
  }

  img {
    height: 50%;
    width: 50%;
    transform: translateY(50%);
  }
}

.display-inline-block {
  display: inline-block;
}
