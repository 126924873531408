.form-label {
  font-size: 0.85em;
  font-style: italic;
  text-align: left;
  margin-top: -0.5em;
  margin-bottom: 0.5em;
  color: var(--text-color-light);
}

.form-select {
  font-family: var(--font-primary);
  color: white;
  width: 100%;
  background-color: transparent;
  border-radius: 0;
  height: 33px;

  option {
    color: black; // For Microsoft Edge
  }
}
