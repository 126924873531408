.copyright-audit-filters {

  .search-filter-picker {
    margin-right: 16px;
  }
}

.copyright-audit-element {
  background-color: #333;
  margin: 16px 0;
  padding: 2px 16px;
  border-radius: 8px;
}

.copyright-audit-row {
  text-align: left;
  margin-top: 16px;
  margin-bottom: 16px;

  &-header, &-body {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    & > * {
      flex-basis: 25%;
    }
  }

  &-header {
    @include vfont-button;
  }

  &-body {
    @include vfont-body;
  }

}

.user-ban-input {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;

  &--button {
    padding: 4px 16px;

    &.unban {
      width: 6em;

      background: rgba(0, 0, 0, 0);
      border: 1px solid var(--color-light-gray);

      &:hover {
        background: var(--color-primary);
        border-color: var(--color-primary);
      }
      &:hover:after {
        content: 'Unban';
      }
      &:hover span {
        display: none;
      }
    }

    &.unblock-img {
      width: 13em;

      background: rgba(0, 0, 0, 0);
      border: 1px solid var(--color-light-gray);

      &:hover {
        background: var(--color-primary);
        border-color: var(--color-primary);
      }
      &:hover:after {
        content: 'Unblock';
      }
      &:hover span {
        display: none;
      }
    }
  }
}
