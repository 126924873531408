.search-input {
  width: 100%;
  height: 24px;
  outline: none;
  padding: 0 8px;
}

.search-sort {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
  gap: 16px;

  & select {
    min-width: 200px;
  }
}

.search-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 30px;
}

.search-filters {
  display: flex;
  flex-direction: column;
  margin-right: 16px;
}

.search-filter-picker {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 8px 0 8px 0;
  padding: 16px;
  background: #333;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.search-no-results {
  margin: 16px 0;
  width: 100%;
}

.beats-search-results {
  width: 100%;
  max-width: 800px;
}

.top-tracks-search-results {
  width: 100%;
  max-width: 675px;
}

.user-profile-image {
  border-radius: 50% !important;
  height: 60px !important;
  width: 60px !important;
}
