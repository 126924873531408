@mixin playback-controls-height {
  height: 32px;
  @include breakpoint($small) {
    height: 48px;
  }
}

$seek-controls-height: 0;
$seek-bar-height: 2px;

.playback-controls-container {
  flex-shrink: 0;
  margin: 4px 0;
  @include playback-controls-height;

  display: flex;
  align-items: center;
  justify-content: center
}

.playback-controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  gap: 8px;
  @include breakpoint($small) {
    gap: 8px;
  }
}

.playback-controls-left {
  flex: 1;
  display: flex;
  justify-content: flex-start;
}

.playback-controls-right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.skip-previous {
}

.skip-next {
}

.play-pause {
}

.player-button {
  cursor: pointer;
  transition: all $transition-speed-fast $transition-ease;

  @include playback-controls-height;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    margin: auto;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &.disabled {
    filter: brightness(30%);
    pointer-events: none;
  }

  &:active {
    filter: brightness(125%);
  }
}

.seek-controls {
  max-width: 1440px;
  transform: translateY(-50%);
  margin: 0;
  width: 100%;
  height: $seek-controls-height;

  flex-grow: 1;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .duration {
    @include vfont-body-thicc;
    opacity: 0.7;
  }
}

.seek-bar {
  cursor: pointer;
  color: var(--color-secondary);
  width: 100%;
  height: $seek-bar-height;
  margin: 0;
}

.audio-player-bar {
  z-index: 10;
  width: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  position: fixed;
  bottom: 0px;

  background: var(--nav-bg-color);

  button.close {
    position: absolute;
    right: 8px;
    top: 16px;
    background: none;
    border: none;

    &:hover {
      cursor: pointer;
    }
  }
}

.with-navigation-sidebar {
  @include breakpoint($small) {
    width: calc(100% - 300px);
    left: 300px;
  }
}

.audio-player-bar-body {
  max-width: 1440px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: calc(100% - 16px);
  margin: 4px 0;

  height: 60px;
  @include breakpoint($small) {
    height: 80px;
  }
}

.audio-player-info {
  overflow: hidden;
  text-wrap: nowrap;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  flex-basis: 0;
  flex-grow: 1;

  &-img {
    width: 36px;
    height: 36px;
    object-fit: cover;
    border-radius: 4px;
  }
}

.audio-player-volume {
  display: none;
  @include breakpoint($small) {
    display: flex;
  }
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  flex-basis: 0;
  flex-grow: 1;

  &-icon {
    cursor: pointer;
    text-align: right;
    display: flex;
    flex-direction: column;

    img {
      width: 100%;
      height: 100%;
      margin: auto;
      object-fit: contain;
      max-height: 32px;
    }
  }
}
