.purchase-beat-boost {
  margin-top: 32px;
  justify-content: center;
  align-items: center;

  .layout-content {
    width: 90vw;
    max-width: 400px;
    margin: 0 auto;
  }

  &__title {
    text-align: left;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 49px;
    margin-bottom: 10px;
  }

  &__description {
    text-align: left;
  }

  &__continue {
    margin-top: 32px;
  }

  &__image-container {
    display: grid;
    position: relative;
  }

  &__image, &__image-content {
    grid-column: 1;
    grid-row: 1;
  }

  &__image {
    width: 90vw;
    aspect-ratio: 1 / 1;
    max-width: 400px;
    max-height: 400px;
    object-fit: cover;
  }

  &__image-content {
    .beatname {
      position: absolute;
      top: 24px;
      left: 24px;
      @include vfont-button;
      color: var(--text-color-light);
    }

    .user-image {
      position: absolute;
      top: 48px;
      left: 24px;
      width: 24px;
      height: 24px;
      border-radius: 12px;
    }

    .username {
      position: absolute;
      top: 48px;
      left: 56px;
      @include vfont-title;
      color: var(--text-color);
    }

    .boost-numbers {
      position: absolute;
      bottom: 24px;
      right: 24px;
      width: 120px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      .lottie {
        height: 40px;
      }
    }
  }
}
