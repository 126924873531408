:root {
  --bg-color: #161616;
  --nav-bg-color: #1F1F1F;
  --nav-el-color: #262626;
  --nav-el-hover-color: #464646;
  --text-color: white;
  --text-color-light: lightgray;
  --color-primary: #3C82DA;
  --color-secondary: #E6005F;
  --color-blue: #3C82DA;
  --color-pink: #E6005F;
  --color-light-gray: #A3A3A3;
  --color-medium-gray: #333;
  --font-primary: Manrope, Arial, sans-serif;
  --white-100: rgba(100%, 100%, 100%, 1.0);
  --white-70: rgba(100%, 100%, 100%, 0.7);
}

@import 'styles/variables';
@import 'styles/mixins';

@import 'styles/typography';
@import 'styles/widgets';
@import 'styles/alert';
@import 'styles/buttons';
@import 'styles/discover';
@import 'styles/form';
@import 'styles/layout';
@import 'styles/login';
@import 'styles/navigation';
@import 'styles/orderable-items';
@import 'styles/producer-file-upload';
@import 'styles/profile';
@import 'styles/search';
@import 'styles/submit';
@import 'styles/submit-track';
@import 'styles/terms-of-service';
@import 'styles/users';
@import 'styles/utils';
@import 'styles/audio-player';
@import 'styles/public-view';
@import 'styles/moderation';
@import 'styles/public-user-profile';
@import 'styles/cookie-consent';
@import 'styles/app-store-banner';
@import 'styles/cell';
@import 'styles/purchase-beat-boost';
@import 'styles/conversations';
