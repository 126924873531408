.cell-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  &:not(.noline)::after {
    content: '';
    border: 0.5px solid var(--color-medium-gray);
    align-self: stretch;
  }
}

.clickable-cell:hover {
  cursor: pointer;
}

.clickable-cell:hover p:first-of-type {
  color: var(--text-color);
}

.cell {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  gap: 16px;
  padding: 8px;
  @include breakpoint($small) {
    padding: 16px;
  }

  color: var(--text-color-light);

  &__image {
    object-fit: cover;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 4px;

    width: 3.5rem;
    height: 3.5rem;
    @include breakpoint($small) {
      width: 5rem;
      height: 5rem;
    }

    &.large {
      width: 5rem;
      height: 5rem;
      @include breakpoint($small) {
        width: 9rem;
        height: 9rem;
      }
    }

    &.fallback {
      object-fit: contain;
      padding: 1.75em;
      &.large {
        padding: 4em;
      }
    }
  }

  &__body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0px;
    @include breakpoint($small) {
      gap: 2px;
    }

    @include vfont-body;
    text-align: left;
  }

  &__line {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;

    word-break: break-all;

    &__title {
      @include vfont-button;
      color: var(--text-color);
    }

    &__notice {
      @include vfont-subtext;
    }

    &__important * {
      @include vfont-subtext;
      color: var(--color-secondary);

      &:hover {
        color: var(--color-secondary);
        opacity: 0.8;
      }
    }

    &__featured {
      content: url(~/src/images/featured_star_small.svg);
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
  }

  &__annotated {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;

    &__image {
      width: 0.6rem;
      height: 0.6rem;
      object-fit: contain;
      filter: brightness(0) saturate(100%) invert(95%) sepia(0%) saturate(0%) hue-rotate(135deg) brightness(91%) contrast(91%);

      &.large {
        width: .9rem;
        height: .9rem;
      }
    }
  }

  &__overflowButton {
    width: 1rem;
    height: 1rem;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__button {
    @include vfont-button;
    padding: 2px 8px;
    color: var(--text-color);
    border: none;
    background: var(--nav-el-hover-color);
    border-radius: 999px;
    min-width: 80px;

    &:hover {
      opacity: 0.6;
    }
  }

  &__audioPlayer {
    height: 20px;
    margin: 4px 0;
    max-width: 100%;
  }
}
