.submit-preface {
  text-align: left;
  font-size: 13px !important;
  opacity: 0.7;

  @include breakpoint($small) {
    margin-top: -25px;
    margin-bottom: 25px;
  }

  @include breakpoint($medium) {
    margin-bottom: 50px;
  }

  &-big {
    opacity: 1;
    font-size: 16px !important;
  }
}
