.app-store-banner {
  max-width: 1440px;
  margin: 16px;
  z-index: 20;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  .container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }
}

.app-store-banner__ctas {
  height: 24px;

  @include breakpoint($small) {
    height: 40px;
  }

  .os-android & {
    a:first-child {
      display: none;
    }
  }

  .os-ios & {
    a:last-child {
      display: none;
    }
  }

  a {
    height: 100%;
    text-decoration: none;
    display: inline-block;
    margin: 0 5px;
  }

  img {
    height: 100%;
  }
}
