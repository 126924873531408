.terms-of-service-container {
  font-size: 12px;
  a {
    color: var(--color-primary);
  }
  ul {
    margin: 18px 0;
    li {
      word-wrap: break-word;
      white-space: normal;
      font-size: 12px;
    }
  }

  p {
    margin-bottom: 0;
  }
}
.terms-of-service {
  font-weight: bold;
  color: #c0c0c0;
  text-align: center;
  text-decoration: none;
}
.terms-of-service:hover {
  color: var(--text-color);
}
