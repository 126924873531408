.app-navigation__toggle {
  position: absolute;
  top: 22px;
  right: 15px;
  appearance: none;
  border: 0;
  background: none;
  height: 24px;
  width: 24px;

  &:focus, &:active {
    outline: 0;
  }

  @include breakpoint($small) {
    display: none;
  }

  img {
    height: 24px;
    z-index: 10000;
    position: relative;
    transition: all $transition-speed $transition-ease;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &.close {
      opacity: 0;
    }
  }

  &.is-open img {
    height: 14px;
    // margin-right: 5px;
    opacity: 1;

    &.burger {
      opacity: 0;
    }
  }
}

.app-navigation {
  height: 100%;
  width: 300px;
  background: var(--nav-bg-color);
  border-right: 1px solid rgba(#a3a3a3, 0.1);
  position: fixed;
  left: -300px;
  transition: left $transition-speed $transition-ease;
  z-index: 100;

  @include breakpoint($small) {
    left: 0;
  }

  &.is-open {
    left: 0;
  }

  hr {
    margin: 20px 0;
    width: 100%;
    height: 1px;
    border: 0;
    background: rgba(#a3a3a3, 0.1);
  }
}

.app-navigation__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: transparent;
  transition: background $transition-speed $transition-ease;
  z-index: 1;

  &.is-open {
    background: rgba(0,0,0,0.7);
    pointer-events: all;
  }

  @include breakpoint($small) {
    pointer-events: none;
    background: transparent;
  }
}

.app-navigation__header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 30px;

  &.mobile {
    position: absolute;
    top: 0;
    left: 0;

    @include breakpoint($small) {
      display: none;
    }
  }
}

.app-navigation__header-logo {
  display: flex;
  align-items: center;
}

.app-navigation__profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px 0 24px 0;
}

.app-navigation__profile-img {
  width: 56px;
  height: 56px;
  border-radius: 50%;
}

.app-navigation__profile-username {
  margin-top: 16px;
  @include vfont-button;
}

.app-navigation__profile-email {
  color: #949494;
  @include vfont-body-thicc;
}

.app-navigation__upload-button {
  background-color: var(--color-secondary);
  margin: 0 40px 32px 40px;
  padding: 12px;

  img {
    margin-right: 8px;
    height: 20px;
    width: 20px;
  }
}

.app-navigation__menu-anchor {
  &.selected {
    &::before {
      content: '';
      position: absolute;
      left: 0;
      width: 3px;
      height: 40px;
      background: var(--color-secondary);
      border-radius: 2px;
      transform: translateY(8%);
    }
  }
}

.app-navigation__menu-button {
  color: lightgray;
  background-color: unset;
  text-align: left;
  margin: 0 40px;
  padding-left: unset;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: unset;

  &.selected, &:hover {
    color: white;
  }
}

.app-navigation__divider {
  background-color: #373737;
  height: 1px;
  margin: 24px 0;
}

.app-navigation__copyright {
  position: absolute;
  bottom: 24px;
  width: 100%;
  text-align: center;
  color: var(--color-light-gray);
}

.navigation-tabs {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 20px;
}

.navigation-tab {
  color: white;
  appearance: none;
  background: none;
  border: 0;
  position: relative;
  padding: 5px 0;
  cursor: pointer;
  opacity: 0.3;
  transition: opacity $transition-speed $transition-ease;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    transition: width $transition-speed $transition-ease;
    height: 3px;
    background: var(--color-secondary);
    border-radius: 5px;
  }

  &.is-active{
    opacity: 1;
    &:after {
      width: 100%;
    }
  }

  &:not(:last-child) {
    margin-right: 42px;
  }
}
