.discover-content {
  margin-top: 32px;
}

.discover-row {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  padding: 48px 0 16px 0;

  &-content {
    > * {
      color: var(--text-color-light);
    }

    position: relative;
    width: 414px;
    height: 100%;
    margin: auto 0;
    text-align: left;

    img {
      opacity: 0.3;
      border-radius: 4px;
      width: 100%;
      height: 128px;
      object-fit: cover;
    }

    &-id {
      position: absolute;
      top: -40px;
      text-align: left;
    }

    &-link {
      position: absolute;
      top: -20px;
      text-align: right;
    }

    &-title {
      position: absolute;
      top: 16px;
      left: 10%;
      width: 100%;
      text-align: left;

      &.center {
        text-align: center;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &-body {
      position: absolute;
      top: 64px;
      left: 10%;
      width: 100%;
      text-align: left;

      &.center {
        text-align: center;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &-button {
      position: absolute;
      top: 64px;
      padding: 4px;
      border-radius: 16px;
      background: var(--color-secondary);

      &.center {
        text-align: center;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

