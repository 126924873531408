a {
  transition: all .3s;
  text-decoration: none;
  cursor: pointer;

  &:not([class]) {
    color: var(--color-primary);
  }

  &:hover {
    color: white;
  }
}

.widgets-Layout {
  padding: 5rem 1rem;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: auto;
  min-height: 0;

  @include breakpoint($small) {
    width: calc(100% - 300px);
    margin-left: 300px;
    padding: 3rem;
  }

  &.fullscreen {
    height: 100vh;
    width: 100vw;
    margin-left: inherit;
    padding: 3rem;
  }
}

.widgets-Layout-Header {
  font-size: 36px;
  font-weight: 900;
  text-align: left;
}

.widgets-Button {
  color: #fff;
  transition: all .3s;
  font-size: 14px;
  background-color: var(--color-primary);
  border-radius: 8px;
  cursor: pointer;
  padding: 16px 32px;
  border: none;

  &.small {
    padding: 4px 16px;
  }

  &.medium {
    padding: 8px 24px;
  }

  &.space-above {
    margin-top: 16px;
  }

  &.red {
    background-color: var(--color-secondary);
  }

  &.red-outlined {
    background-color: unset;
    border: 2px solid var(--color-secondary);
    color: var(--color-secondary);
  }

  &.red-text {
    background-color: unset;
    border: unset;
    color: var(--color-secondary);
  }

  img {
    vertical-align: middle;
    margin-right: 15px;
    height: 16px;
    width: 16px;
  }

  &.flexrow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    filter: brightness(125%);
  }

  &[disabled] {
    filter: brightness(60%);
    pointer-events: none;
  }
}

.widgets-Form-Button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .spinner {
    border-radius: 50%;
    width: 16px;
    height: 16px;
    border-top: 2px solid rgba(0,0,0,0);
    border-right: 2px solid white;
    border-bottom: 2px solid white;
    border-left: 2px solid white;
    animation: load8 1.1s infinite linear;
    margin: 0 20px;
  }

}

.widgets-Form-Item {
  margin: 0 0 16px 0;
  display: flex;
  flex-direction: column;
  text-align: left;

  label {
    margin: 0 0 4px 0;
  }

  input {
    padding: 0 8px;
  }

  select {
    padding: 0 4px;
  }

  .widgets-Input {
    display: flex;
    flex-direction: row;
    align-items: center;

    border: 1px solid var(--color-light-gray);
    border-radius: 4px;
    transition: all .3s;

    &:hover, &:focus {
      border: 1px solid var(--color-primary);
    }

    &-input {
      margin: unset;
      flex: 1;
      border: unset;

      &:hover, &:focus {
        border: unset;
      }
    }

    &-before {
      margin: 0 0 0 8px;
      font-family: var(--font-primary);
      font-size:  0.875rem;
      color: var(--color-light-gray);
    }
  }

  input, select, textarea {
    margin: 4px 0 0 0;
    height: 30px;
    background: transparent;
    transition: all .3s;
    outline: none;
    border: 1px solid var(--color-light-gray);
    border-radius: 4px;
    color: white;
    font-family: var(--font-primary);
    font-size:  0.875rem;

    &:hover, &:focus {
      border: 1px solid var(--color-primary);
    }

    &[readonly] {
      color: var(--color-light-gray);
      cursor: not-allowed;
      &:hover, &:focus {
        border: 1px solid var(--color-light-gray);
      }
    }
  }

  textarea {
    transition: height 0s;
    resize: none;
    height: unset;
  }
}


.widgets-Dropdown {
  &-Anchor {
    cursor: pointer;
  }

  &-Actions {
    display: none;
    border-radius: 8px;
    text-align: left;
    background-color: var(--color-medium-gray);
    position: absolute;
    min-width: 100px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    transform: translateX(-80%);
    z-index: 1;
    overflow: hidden;

    &.show {
      display: unset;
    }
  }

  &-Action {
    @include vfont-button;
    padding: 8px 12px;
    cursor: pointer;

    &:hover {
      background-color: var(--nav-el-hover-color);
    }
  }
}

.widgets-CheckboxGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  white-space: nowrap;

  label {
      color: var(--text-color);
  }
}

.widgets-Checkbox {
  @include vfont-button;
}

.widgets-Tooltip {
  z-index: 1;
  opacity: 1 !important;

  &:hover &-Content {
    visibility: visible;
  }

  &-Content {
    z-index: 1;
    opacity: 1 !important;
    visibility: hidden;
    color: white !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    background-color: black;
    word-break: normal;
    text-align: left;
    padding: 8px;
    border-radius: 8px;

    position: absolute;
    max-width: 240px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    transform: translate(-50%, 20%);

    &::before {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 50%;
      border-bottom: .6em solid black;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
    }
  }
}

.admin-feed {
  .widgets-CheckboxGroup {
    flex-wrap: wrap;
    height: 110px;
  }
}
