@use "sass:math";

*,
*:before,
*:after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(#000000, 0);
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-family: var(--font-primary);
  font-weight: 500;
}

@font-face {
    font-family : 'Manrope';
    font-style  : normal;
    font-weight : 400;
    src         : url(~/src/fonts/manrope/manrope-regular.otf);
}

@font-face {
    font-family : 'Manrope';
    font-style  : normal;
    font-weight : 500;
    src         : url(~/src/fonts/manrope/Manrope-SemiBold.otf);
}

@font-face {
    font-family : 'Manrope';
    font-style  : normal;
    font-weight : 700;
    src         : url(~/src/fonts/manrope/manrope-bold.otf);
}

@font-face {
    font-family : 'Manrope';
    font-style  : normal;
    font-weight : 900;
    src         : url(~/src/fonts/manrope/manrope-extrabold.otf);
}

@mixin vfont-14 {
  font-size: 14px;
  font-weight: 500;
}

@mixin vfont-20 {
  font-size: 20px;
  font-weight: 500;
}

@mixin vfont-18 {
  font-size: 18px;
  font-weight: 900;
}

@mixin vfont-26 {
  font-size: 26px;
  font-weight: 900;
}

@mixin vfont-title-big {
  font-size: 20px;
  font-weight: 900;
}

@mixin vfont-title {
  font-size: 18px;
  font-weight: 900;
}

@mixin vfont-button {
  font-size: 14px;
  font-weight: 700;
}

@mixin vfont-subtext {
  font-size: 10px;
  font-weight: 400;
}

@mixin vfont-body {
  font-size: 12px;
  font-weight: 400;
}

@mixin vfont-body-big {
  font-size: 14px;
  font-weight: 400;
}

@mixin vfont-body-thicc {
  font-size: 12px;
  font-weight: 700;
}

pre,
pre * {
  color: white !important;
}

%heading {
  font-family: var(--font-primary);
  line-height: 1.25;
  font-weight: bold;
  margin: 0.5rem 0;
  color: white;

  &:first-child {
    margin: 0 0 0.5em 0;
  }
}

h1:not([class]),
.h1 {
  @extend %heading;
  @include font-size(math.div(40, 16));
  margin: 0.25rem 0;

  &:first-child {
    margin: 0 0 0.25em 0;
  }

  @include breakpoint($small) {
    @include font-size(math.div(42, 16));
  }

  @include breakpoint($medium) {
    @include font-size(math.div(64, 16));
  }
}

h2:not([class]),
.h2 {
  @extend %heading;
  @include font-size(2);

  @include breakpoint($medium) {
    @include font-size(2.5);
  }
}

h3:not([class]),
.h3 {
  @extend %heading;
  @include font-size(1.75);

  @include breakpoint($medium) {
    @include font-size(2);
  }
}

h4:not([class]),
.h4 {
  @extend %heading;
  @include font-size(1.5);
}

h5:not([class]),
.h5 {
  @extend %heading;
  @include font-size(1.25);
}

h6:not([class]),
.h6 {
  @extend %heading;
  @include font-size(1);
}

%p {
  font-family: var(--font-primary);
  font-size: 1.125rem;
  line-height: 1.5;
  color: white;

  &:first-of-type {
    margin-top: 0;
  }
}

p:not([class]),
.p,
p.p1 {
  @extend %p;
}

.sr-text {
  font-size: 0;
  position: absolute;
  left: -999999px;
}
