.conversation {
  &_container {

    &_header {
      width: 100%;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &_spacer {
        flex-grow: 1;
      }
      &_username {
        width: 100%;
        text-align: center;
      }

      &_actions {
        margin-left: auto;
        padding-bottom: 10px;
      }
    }
  }

  &_messages-container {
    height: 70vh;
    overflow-y: scroll;
    margin-top: 25px;
  }

  &_preview {
    &_body {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
    }

    &_time {
      color: var(--color-light-gray);
    }

    &_message {
      color: var(--color-light-gray);
      margin: 0px;
      display: -webkit-box;
      line-clamp: 3;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &_profile-image {
        border-radius: 50%;
        border: 1px solid gray;
        padding: 4px;
        height: 40px;
        width: 40px;
    }

    &_unread-dot {
      width: 10px;
      height: 10px;
      background-color: var(--text-color);
      border-radius: 50%;
      display: inline-block;
      margin-left: auto;
    }
  }

  &_message {
    display: block;
    white-space: pre-wrap;
    color: white;
    padding: 10px 15px;
    border-radius: 15px;
    max-width: min(100%, 70vh);
    word-wrap: break-word;

    &_cell {
      padding: 4px;
    }

    &_self {
      margin-left: auto;
      background-color: var(--color-blue);
    }

    &_other {
      margin-right: auto;
      background-color: var(--color-medium-gray);
    }

    &_no-icon {
      margin-left: 46px;
    }

    &_header {
      height: 25px;
      padding-top: 6px;
      padding-bottom: 2px;

      &_paragraph {
        font-size: 12px;
        display: flex;
        gap: 4px;
        justify-content: center;
      }
    }

    &_profile-image {
      border-radius: 50%;
      border: 1px solid gray;
      padding: 4px;
      height: 20px;
      width: 20px;
    }

    &_input-container {
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 10px;
      margin-top: 20px;
      outline: none;
    }

    &_input-bar {
      display: flex;
      width: 75vw;

      height: auto;
      min-height: 55px;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 10px;
      margin-top: 20px;
      padding: 0 10px;
      background-color: var(--color-medium-gray);
      border-radius: 15px;
    }

    &_input {
      background-color: var(--color-medium-gray);
      color: var(--text-color);
      border: none;
      text-align: left;
      height: 100%;
      padding: 19px 0 4px 2px;
      box-sizing: border-box;
      resize: none;
      flex-grow: 1;
      outline: none;
    }

    &_send-button {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: var(--color-medium-gray);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      cursor: pointer;

      &:hover {
        background-color: var(--color-light-gray);
      }
    }
  }

  &_empty-state {
    margin-top: 25%;
  }
}

.messages-header {
  display: flex;
  justify-content: space-between;

  &_actions {
    display: flex;
    gap: 32px;
    align-items: center;

    &_new-message {
      display: flex;

      img {
        align-items: center;
      }
    }
  }
}

.messages-empty-state {
    margin: auto 0;
}

.messages-settings {

  &_header {
    color: var(--color-secondary);
    cursor: unset;
    width: 200px;

    &:hover {
      background-color: unset;
    }
  }

  &_item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_selected {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: var(--color-blue);
    }
    &_unselected {
      width: 12px;
      height: 12px;
      border: 2px solid var(--color-light-gray);
      border-radius: 50%;
    }
  }
}

.new-message-container {
  padding-top: 12px;
}
