.users-list-row {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  margin: 7px 0;
  padding: 30px;
  background: #333;
  margin: 8px 0;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

  @include breakpoint($medium) {
    flex-direction: row;
  }

  &.admin {
    @include breakpoint($medium) {
      flex-direction: column;
    }
    @include breakpoint($medium) {
      flex-direction: row;
    }

    img {
      width: 100px;
      height: 100px;
      min-width: 100px;

      @include breakpoint($medium) {
        align-self: flex-start;
      }
    }
  }

  img {
    align-self: center;
    height: 100px;
    width: 100px;
    min-width: 100px;

    @include breakpoint($medium) {
      align-self: none;
    }
  }
}
