.btn--gray {
  background: #333;
  border: 0;

  &:hover {
    background: var(--color-primary);
  }
}

.btn--wide {
  width: 360px;
  max-width: 100%;
}

.btn-icon {
  margin-right: 12px;
}

.btn--white {
  background: transparent;
  border: 1px solid white;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 50px;
  line-height: 1;
  color: white !important;
  transition: all $transition-speed $transition-ease;

  &:hover {
    text-decoration: none !important;
    opacity: 0.9;
    background: transparent;
  }
}

.btn--custom {
    outline: none;
    background: transparent;
    border: 1px solid white;
    padding: 8px 20px;
    border-radius: 50px;
    width: 150px;
    color: white !important;
    transition: all $transition-speed-fast $transition-ease;
    margin: 10px 0;

    @include breakpoint($x-small) {
        margin: 0 10px;
    }

    @include breakpoint($medium) {
        margin: 5px 0;
    }

    &.primary {
        &:hover {
            text-decoration: none !important;
            opacity: 1;
            background: var(--color-primary);
        }
    }

    &.secondary {
        &:hover {
            text-decoration: none !important;
            opacity: 1;
            background: var(--color-secondary);
        }
    }
}
