.alert {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 4200;
  background-color: rgba(0, 0, 0, 0.6);

  display: flex;
  align-items: center;
  justify-content: center;

  visibility: hidden;
  opacity: 0;
  transition: all $transition-speed $ease;

  &.show {
    visibility: visible;
    opacity: 1;
  }

  &-body {
    display: flex;
    gap: 16px;
    flex-direction: column;
    align-items: left;
    justify-content: center;

    box-shadow: 0 0 16px rgba(0, 0, 0, .5);
    border-radius: 8px;
    padding: 32px;
    color: var(--text-color);
    background-color: var(--color-medium-gray);

    width: 60vw;
    max-width: 264px;

    &-wide {
      max-width: 480px;
    }

    &-title {
      @include vfont-title-big;
    }

    &-message {
      @include vfont-body-big;
      max-height: 90vh;
      overflow: auto;
    }

    &-button {
      margin-top: 12px;
    }
  }
}

.toast {
  position: fixed;

  left: 50%;
  transform: translateX(-50%);

  z-index: 4300;

  width: 200px;
  background-color: var(--color-medium-gray);

  min-height: 48px;
  min-width: 100px;

  display: flex;
  align-items: center;
  justify-content: center;

  bottom: 16px;
  visibility: hidden;
  opacity: 0;
  transition: all $transition-speed $ease;

  border-radius: 8px;
  box-shadow: 0 0 16px rgba(0, 0, 0, .5);

  &.show {
    visibility: visible;
    opacity: 1;
    bottom: 64px;
  }

  &-message {
    display: flex;
    gap: 16px;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include vfont-body-big;

    padding: 16px;
    color: var(--text-color);
  }
}
