.cookie-consent {
  z-index: 999;
  box-shadow: 0 0 18px rgba(0, 0, 0, 1);

  position: fixed;
  bottom: 24px !important;
  transform: translateX(-50%);
  left: 50vw;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background: var(--nav-bg-color);
  color: white;
  border-radius: 8px;

  width: 50%;
  min-width: 360px;

  &__content {
    margin: 16px;
  }

  &__button {
    white-space: nowrap;
    background: white;
    border: 0px;
    border-radius: 4px;
    box-shadow: none;
    color: black;
    cursor: pointer;
    padding: 4px 8px;
    margin: 16px;
  }
}
