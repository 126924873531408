$item-margin: 8px;

.public-view-background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    filter: blur(3rem);
    opacity: 0.25;
  }
}

.public-view-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 4vh;

  @include breakpoint($small) {
    margin-top: 12vh;
  }
}

.public-view-stats {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  gap: 48px;
  @include breakpoint($small) {
    gap: 72px;
  }
}

.public-view-artwork {
  border-radius: 8px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
  width: 75vmin;
  height: 75vmin;

  @include breakpoint($small) {
    width: 40vmin;
    height: 40vmin;
  }
}

.public-view-play-pause-overlay {
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;

  img {
    height: 25%;
    width: 25%;
    transform: translateY(150%);
  }
}

.public-view-plays-overlay {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  right: 8px;
  top: 8px;

  @include breakpoint($small) {
    right: 12px;
    top: 12px;
  }

  img {
    width: 12px;
    height: 12px;
    object-fit: contain;

    @include breakpoint($small) {
      width: 16px;
      height: 16px;
    }
  }

  span {
    @include vfont-body;

    @include breakpoint($small) {
      @include vfont-body-big;
    }
  }
}

.public-view-username-overlay {
  position: absolute;
  width: 100%;
  bottom: 24px;
}

.public-view-item-title {
  margin-top: 24px;
  color: var(--white-70);
  @include vfont-14;

  @include breakpoint($small) {
    @include vfont-20;
  }
}

.public-view-stat {
  &.value {
    @include vfont-button;
  }

  &.image {
    width: 16px;
    height: 16px;
    object-fit: contain;

    @include breakpoint($small) {
      width: 24px;
      height: 24px;
    }
  }
}
