.producer-file-upload {
  display: grid;
  text-align: center;
  background-color: #333;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  background-position: center center;
  background-size: cover;

  &.has-image {
    img, .producer-file-upload__title, .producer-file-upload__description {
      opacity: 0;
      transition: opacity $transition-speed $transition-ease;
    }
    &:not(.readOnly):hover {
      img, .producer-file-upload__title, .producer-file-upload__description {
        opacity: 1;
      }
    }
  }

  label {
    grid-row: 1;
    grid-column: 1;

    padding: 32px 25px;

    &.readOnly {
      cursor: not-allowed;
    }
    &:not(.readOnly) {
      cursor: pointer;
    }
  }

  img {
    width: 32px;
  }

  .overlay {
    grid-row: 1;
    grid-column: 1;
    background: transparent;
    transition: background $transition-speed $transition-ease;
  }

  &:not(.readOnly):hover .overlay {
    background: rgba(0,0,0,0.65);
  }
}

.producer-file-upload-field {
  width: 100%;
  grid-row: 1;
  grid-column: 1;
  opacity: 0;
  margin: 80px 0 0 60px !important;
  pointer-events: none;
  overflow: hidden;
}

.producer-file-upload__title {
  margin-top: 25px;
  color: white;
  font-size: 0.875rem;
  text-align: left;
}

.producer-file-upload__description {
  margin-top: 10px;
  p {
    font-size: 0.75rem !important;
    color: var(--color-light-gray) !important;
    text-align: left;
  }
}

.producer-file-upload__audio-file-name {
  grid-row: 1;
  grid-column: 1;

  pointer-events: none;

  display: flex;
  justify-content: center;
  align-items: center;

  word-wrap: break-word;
  font-size: 0.875rem;
  text-align: left;

  padding: 16px;
}

.producer-file-upload:hover .producer-file-upload__audio-file-name {
  opacity: 0.25;
}
