.public-user-profile {
  &__bio {
    @include vfont-body;
    white-space: pre-wrap;

    @include breakpoint($small) {
      @include vfont-body-big;
    }
  }

  &__social {
    margin: 16px 0;
    display: flex;
    flex-flow: wrap;
    gap: 8px;
    justify-content: center;
    align-items: center;
    max-width: 400px;

    a {
      color: white;
      border: 0.5px solid white;
      border-radius: 4px;
      padding: 0 4px;
      place-content: center;
      font-size: 10px;

      @include breakpoint($small) {
        font-size: 12px;
      }
    }
  }
}
