.producer-profile-pic {
  object-fit: cover;
  width: 100%;
  border-radius: 5px;
}

#producer-profile-image {
  display: none;
}

.producer-profile__container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include breakpoint(900px) {
    flex-direction: row;
  }

  .producer-profile__left {

    .producer-profile__img-label {
      display: grid;
      place-items: center;
    }

    .producer-profile-pic {
      grid-row: 1;
      grid-column: 1;

      width: 120px;
      height: 120px;
      border-radius: 50%;

      &.edit {
        cursor: pointer;
      }

      &.overlay {
        &:hover {
          transition: all .3s;
          background: rgba(0,0,0,.4);
        }
      }
    }

    .producer-profile-pic-camera-icon {
      grid-row: 1;
      grid-column: 1;

      width: 30px;
      height: 30px;
    }

    @include breakpoint($small) {
      justify-content: flex-start;
      width: 180px;
    }
  }

  .characters-remaining {
    @include vfont-body;
  }

  .producer-profile__right {
    flex: 1;
    display: flex;
    flex-direction: column;

    button[type=submit] {
      margin-top: 24px;
      width: 100%
    }
  }
}

.producer-profile__header {
  text-align: left;

  .profile-view & {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    a {
      align-self: center;
    }
  }
}

.producer-profile__img-field {
  @include breakpoint($small) {
    width: 180px;
  }

  @include breakpoint(900px) {
    margin-bottom: 0;
    padding-bottom: 0 !important;
  }
  .profile-view & {
    label {
      pointer-events: none;
    }
  }

  .producer-profile__image-input {
    position: absolute;
    left: -999999px;
  }
}

.producer-profile__view-content {
  text-align: left;
  flex: 1;
  justify-content: space-between;
  display: flex;
  flex-direction: column;

  p {
    font-size: 0.875rem !important;
  }
}

.producer-profile__view-title {
  color: white;
  font-weight: 900;
  font-size: 24px;
  line-height: 33px;
}

.producer-profile__view-subtitle {
  color: white;
  font-weight: 400;
  font-size: 12px;
}

.producer-profile__view-bio {
  margin: 24px 0 0 0;

  pre {
    white-space: pre-wrap;
  }
}

.producer-profile__social {
  margin-top: 40px;

  display: flex;
  flex-flow: wrap;
  gap: 8px;

  a {
    color: white;
    font-size: 12px;
    border: 0.5px solid white;
    border-radius: 4px;
    padding: 0 4px;
    place-content: center;
  }
}
