.welcome {
  height: 100vh;
  width: 100%;
  display: grid;

  grid-template-rows: 52px auto 52px;
  grid-template-columns: 1fr;
  grid-template-areas:
    "header"
    "content"
    "footer";
}

.welcome::before {
  content: "";
  position: fixed;
  left: 0;
  right: 0;
  z-index: -1;

  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(100deg, var(--color-blue), var(--color-pink) 120%);

  @include breakpoint($small) {
    background: linear-gradient(90deg, var(--color-blue), var(--color-pink) 120%);
  }
}

.welcome-header {
  text-align: left;
  grid-area: header;
}

.login-menu {
  text-align: left;
}

.welcome-content {
  grid-area: content;
  place-self: center;
  padding: 0 0 40px 0;
}

.welcome-title {
  font-size: 48px;
  font-weight: 900;
  margin: 0 0 42px 0;

  @include breakpoint($small) {
    font-size: 72px;
  }
}

.welcome-body {
  font-size: 1rem;
  font-weight: 400;
  text-align: center;

  &-content {
    text-align: left;
  }
}

.welcome-footer {
  grid-area: footer;
}

.firebase-auth-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.widgets-Button {
  &.google, &.facebook, &.apple, &.beatstars, &.phone {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 312px;
    height: 24px;
    padding: 8px;
    margin: 8px 0;
  }

  &.google {
    color: #333;
    background-color: white;
  }

  &.facebook {
    color: white;
    background-color: #4267B2;
  }

  &.apple {
    color: white;
    background-color: black;
  }

  &.beatstars {
    color: white;
    background-color: black;
  }

  &.phone {
    color: white;
    background-color: var(--color-medium-gray);
  }
}
