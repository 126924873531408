.orderable-items-move {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;
  min-width: 150px;
  margin: auto 16px;

  button {
    background: transparent;
    border: 1px solid white;
    padding: 2px;
    border-radius: 50px;
    width: 125px;
    color: white !important;
    transition: all $transition-speed-fast $transition-ease;
    margin: 5px 0;

    &:hover {
      text-decoration: none !important;
      opacity: 0.6;
    }
  }
}
