#root {
  height: 100%;
}

.content {
  height: 100%;
  max-width: 1440px;
  line-height: 1.5;
  overflow: auto;
  position: relative;
  margin: 0 auto;
  text-align: center;
  color: var(--text-color);
  border-radius: 0 0 10px 10px;
}
.content::before {
  content: "";
  position: fixed;
  left: 0;
  right: 0;
  z-index: -2;

  display: block;
  width: 100%;
  height: 100%;
  background-color: var(--bg-color);
}

.body-container {
  display: flex;

    &--fullscreen {
      height: 100%;
    }
}

.layout-content {
  padding: 3rem 0;
}

@media screen and (max-width: 767px) {
  .layout-content {
    padding: 1rem 0;
  }
}

.firebase-emulator-warning {
  display: none;
}
