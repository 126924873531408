@mixin font-size($sizeValue: 1) {
	font-size: ($sizeValue * 16) * 1px;
	font-size: $sizeValue * 1rem;
}

@mixin breakpoint($size) {
	@media screen and (min-width: $size) {
		@content
	}
}
