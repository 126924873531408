.submit-track {
  display: flex;
  flex-direction: column;

  @include breakpoint(750px) {
    flex-direction: row;
  }

  @include breakpoint($small) {
    flex-direction: column;
  }

  @include breakpoint(1100px) {
    flex-direction: row;
  }
}

.submit-track__left {
  display: flex;
  flex-direction: column;

  @include breakpoint(500px) {
    flex-direction: row;
  }

  @include breakpoint(750px) {
    width: 165px;
    flex-direction: column;
    margin-right: 30px;
  }

  @include breakpoint($small) {
    width: 100%;
    flex-direction: row;
    margin-right: 0;
  }

  @include breakpoint(1100px) {
    width: 165px;
    flex-direction: column;
    margin-right: 30px;
  }
}

.submit-track__right {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
